import styled from 'styled-components';
import { H2, P, H3 } from 'typography';
import Anchor from 'components/Anchor';
import { Button } from 'components/buttons';

const MSContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        flex-direction: row;
        justify-content: center;
    }
`;

const TextContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 548px;
`;

const HeadingStyled = styled(H2)`
    margin-top: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const QuoteContainerStyled = styled.div`
    background: ${({ theme }) => theme.palette.grey[300]};
    padding: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const QuoteStyled = styled(H3)`
    font-family: ${({ theme }) => theme.fonts.variant};
    font-size: 100px;
    color: ${({ theme }) => theme.palette.primaryDark};
    margin-top: ${({ theme }) => theme.spacing[4]};
    padding: 0;
    margin-bottom: 0;
    line-height: 0.2;
`;

const NameParagraphStyled = styled(P)`
    color: ${({ theme }) => theme.palette.primaryDark};
    font-size: 14px;
    margin-top: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

const ParagraphStyled = styled(P)`
    color: ${({ theme }) => theme.palette.primaryDark};
    margin-top: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

const ButtonStyled = styled(Button).attrs({
    variant: Button.Variant.LINK,
})`
    padding: 0 0 ${({ theme }) => theme.spacing[0.5]}; 0;
    text-decoration: underline;
`;

const LinkStyled = styled(Anchor)`
    justify-content: start;
    padding-left: ${({ theme }) => theme.spacing[0]};
    padding-right: ${({ theme }) => theme.spacing[0]};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }
`;

const ParagraphLinkStyled = styled(P)`
    margin-top: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme }) => theme.spacing[1.5]};
`;

export {
    MSContainerStyled,
    TextContainerStyled,
    HeadingStyled,
    QuoteContainerStyled,
    QuoteStyled,
    ParagraphStyled,
    NameParagraphStyled,
    ParagraphLinkStyled,
    ButtonStyled,
    LinkStyled,
};
